@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 2px !important; 
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #fecf0d; 
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-tel-input .form-control {
  width: 100% !important;
}


.toll {
  width: 100% !important;
  border-radius: 5px;
  overflow: hidden;
}


.DateRangePicker__Month {
  color: #333;
  display: inline-block;
  margin: 0 20px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 45% !important;
}

.css-13cnpq9-MuiPickersLayout-root {
  display: flex !important;
  flex-direction: column !important;
  min-width: 250px !important;
  background-color: #fff;
}

.MuiPickersLayout-shortcuts {
  display: flex !important;
  justify-content: flex-start;
  width: 100% !important;
  max-width: 100% !important;
}

.css-jef1b6-MuiDateRangeCalendar-container{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.css-13cnpq9-MuiPickersLayout-root .MuiPickersLayout-toolbar {
  display: none !important;
}

.css-qyw6ft-MuiListItem-root {
  max-width: fit-content !important;
}

@media only screen and (max-width: 960px) {
  .DateRangePicker__Month {
    color: #333;
    display: inline-block;
    margin: 0 auto 0 0;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    width: 90% !important;
  }
}

@media only screen and (max-width: 768px) {
  .MuiPickersLayout-shortcuts {
    display: flex !important;
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: auto; 
  }
 
  .MuiPickersLayout-shortcuts::-webkit-scrollbar {
    height: 1px; 
  }
  
  .MuiPickersLayout-shortcuts::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  .MuiPickersLayout-shortcuts::-webkit-scrollbar-thumb {
    background-color: #fecf0d;
    border-radius: 2px;
  }

  .MuiPickersLayout-shortcuts {
    scrollbar-width: thin; 
  }

  .css-7p9126-MuiDateRangeCalendar-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: column !important;
  }

  .css-jef1b6-MuiDateRangeCalendar-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: none !important;
  }
}


.z9995 {
  z-index: 9995;
}

.css-7p9126-MuiDateRangeCalendar-root > div:first-of-type {
  display: none !important;
}