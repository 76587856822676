@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import 'react-datepicker/dist/react-datepicker.css';


/* Add these styles to your CSS file */
.hayaanc1wE_ {
  position: relative;
}

.hayaansort-options-tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 220px;
  z-index: 1000;
  margin-top: 8px;
  padding: 8px 0;
  text-align: left;
}

.hayaansort-option-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.hayaansort-option-item:hover {
  background-color: #f5f5f5;
}

.hayaanc1wE_-handle {
  cursor: pointer;
}

.hayaanc1wE_-handle.active {
  background-color: #f5f5f5;
}

/* Animation for the tooltip */
.hayaansort-options-tooltip {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.carousel-wrapper {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 1rem;
}

.carousel-slide {
  scroll-snap-align: start;
  min-width: 250px;
  flex: 0 0 auto;
}

.custom-font {
  font-family: 'TT Hoves Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}


.o-C0978-tab-panel-content-wrapper {
  display: flex
;
  flex-direction: column;
  row-gap: 32px;
  padding: 0 0px 0px !important;
}

.backToBack {
  background-color: #fecf0d;
}


.css-13cymwt-control {
  border-color: transparent !important;
}

.cssTroller {
  border: 1px solid lightgray !important;
  border-color: lightgray !important;
  border-radius: 5px;
}

body {
  font-family: 'Montserrat', sans-serif;
}


.calendar-container {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.calendar-header {
  align-items: center;
}

.calendar-header h2 {
  font-weight: bold;
}

.calendar-navigation{
  display: flex;
  align-items: center;
  margin-right: auto;
}

.calendar-navigation button {
  margin-right: auto;
  padding: 8px 12px;
  background-color: #2b2e35;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  transition: all 0.3s ease-in-out;
}

.calendar-navigation button:hover {
  background-color: #fecf0d;
}

.calendar-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.calendar-footer button {
  padding: 8px 12px;
  background-color: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sunday {
  background-color: #f0f0f0;
}


@media only screen and (max-width: 960px) {
  .calendar-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    text-align: left;
  }
}